import { node } from 'prop-types';

/**
 * An incredibly simple component that just renders children if supplied
 *
 * Useful if you want to render an OPTIONAL wrapper component controlled by a prop,
 * because simply using `({ children }) => <>{children}</>` in the component leads
 * to coverage problems when shallow rendering as it isn't actually invoked by the test
 *
 * Example usage:
 *
 *   const Blurb = ({ text, boxed }) => {
 *     const OptionalBox = hasBox ? Box : Null;
 *     return (
 *       <OptionalBox>
 *         <p className={styles.blurb}>
 *           {text}
 *         </p>
 *       </OptionalBox>
 *     );
 *   };
 */
const Null = ({ children }) => children;

Null.propTypes = {
  children: node,
};

Null.defaultProps = {
  children: null,
};

export default Null;
