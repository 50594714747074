import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Modal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';

import { getSwapUnavailableProductModal } from 'redux/modules/trolley/selectors/get-swap-unavailable-product-modal';
import SubsProductList from './SubsProductList';
import UnavailableProduct from './UnavailableProduct';

import styles from './index.scss';

const SwapUnavailableProductModal = ({
  BodyButton,
  onCloseModal,
  titleText,
  subTitleText,
  productPodAddText,
  isProductRemovable = true,
}) => {
  const { isOpen, lineNumber, trolleyItemId } = useSelector(getSwapUnavailableProductModal);

  return (
    <Modal
      className={styles.modal}
      handleModalClose={onCloseModal}
      isOpen={isOpen}
      titleText={titleText}
      contentClassName={styles['modal-content']}
    >
      <>
        <UnavailableProduct
          lineNumber={lineNumber}
          trolleyItemId={trolleyItemId}
          isProductRemovable={isProductRemovable}
        />
        <SubsProductList
          lineNumber={lineNumber}
          headingText={subTitleText}
          productPodAddText={productPodAddText}
        />
        <section className={styles.buttonWrapper}>
          <BodyButton />
        </section>
      </>
    </Modal>
  );
};

SwapUnavailableProductModal.displayName = 'SwapUnavailableProductModal';
SwapUnavailableProductModal.propTypes = {
  BodyButton: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  onCloseModal: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  subTitleText: PropTypes.string.isRequired,
  productPodAddText: PropTypes.string,
  isProductRemovable: PropTypes.bool,
};
SwapUnavailableProductModal.defaultProps = {
  productPodAddText: 'Select',
  isProductRemovable: true,
};

export default memo(SwapUnavailableProductModal);
